<script setup>
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRouter, useToast, useValidateAccountActions } from '@base/hooks';
import { computed, ref } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const { validateAccountActions } = useValidateAccountActions();
const store = useStore();
const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const isSubmiting = ref(false);

const selectedFlow = computed(() => store.getters['formFlow/getSelectedFlow']);

const disableToEditFormFlow = async () => {
  if (validateAccountActions()) {
    try {
      isSubmiting.value = true;
      await store.dispatch('formFlow/disableFormFlow');
      router.push({ path: selectedFlow.value.links.edit });
      emit('close');
      toast.success(t('flowModule.modalEditEnabled.notification.success'));
    } catch (error) {
      toast.error(t('flowModule.modalEditEnabled.notification.error'));
    } finally {
      isSubmiting.value = false;
    }
  }
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.modalEditEnabled.header')"
    data-testid="formFlowEditEnabledModalContainer"
    @close="$emit('close')"
  >
    <template #default>
      <p>
        {{ $t('flowModule.modalEditEnabled.title') }}
      </p>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalEditEnabledCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          :loading="isSubmiting"
          data-testid="modalEditEnabledButtonSubmit"
          @click="disableToEditFormFlow"
        >
          {{ $t('flowModule.modalEditEnabled.action') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.actions {
  display: flex;
  gap: var(--space-regular);
}
</style>
