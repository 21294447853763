const general = {
  loading: 'Cargando',
  saving: 'Guardando...',
  filters: 'Filtros',
  use: 'Usar',
  disabledTextInput: 'Caja de texto deshabilitada',
  disabled: 'Deshabilitado',
  useTerms: 'Términos de uso',
  and: 'y',
  action: {
    select: 'Seleccionar',
    selectDots: 'Seleccionar...',
    selectOption: 'Seleccionar una opción',
    selectDate: 'Seleccionar una fecha...',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save: 'Guardar',
    saveChange: 'Guardar cambio',
    send: 'Enviar',
    advance: 'Avanzar',
    back: 'Regresar',
    next: 'Siguiente',
    nextPage: 'Siguiente',
    previous: 'Anterior',
    create: 'Crear',
    remove: 'Eliminar',
    move: 'Mover',
    add: 'Agregar',
    delete: 'Eliminar',
    ignore: 'Ignorar',
    open: 'Abrir',
    close: 'Cerrar',
    refresh: 'Actualizar',
    edit: 'Editar',
    download: 'Descargar',
    duplicate: 'Duplicar',
    disable: 'Desactivar',
    preview: 'Previsualizar',
    ok: 'Ok',
    seeMore: 'Ver más',
    seeAll: 'Ver todo',
    seeMoreFromGroup: 'Ver más firmantes del grupo',
    seeMoreSigners: 'Ver más firmantes',
    seeMoreGroups: 'Ver más grupos',
    continue: 'Continuar',
    fix: 'Corregir',
    yes: 'Sí',
    no: 'No',
    upgrade: 'Actualizar',
    help: 'Ayuda',
    tryAgain: 'Intentar nuevamente',
    conclude: 'Concluir',
    out: 'Salir',
    search: 'Buscar',
    finish: 'Finalizar',
    saveAndBack: 'Guardar y regresar',
    discardChanges: 'Descartar cambios',
    resend: 'Reenviar',
  },
  person: {
    legalPerson: 'Persona Jurídica',
    naturalPerson: 'Persona Física',
  },
  feature: {
    attention: 'Atención',
    beta: 'Beta',
    new: 'Nuevo',
    more_info: 'Conoce más.',
    testBetaFeatures: 'Prueba nuevas características antes de que se lancen oficialmente.',
    moreInfo: 'Conoce más',
  },
  date: {
    days: '{days} día | {days} días',
    today: 'Hoy',
    yesterday: 'Ayer',
  },
  form: {
    requiredFields: 'Campos obligatorios',
  },
  badge: {
    new: '¡Nuevo!',
  },
  secureEnvironment: 'Ambiente seguro Clicksign',
  slowLoading: 'Esta página está tardando más de lo esperado en cargar',
  help: {
    email: 'ajuda@clicksign.com',
    helpCenter: 'Centro de ayuda',
    support: 'Hablar con soporte',
  },
  placeholders: {
    phoneNumber: '(__) ____-____',
    birthday: 'dd/mm/aaaa',
    cpf: '___.___.___-__',
  },
  statusAlert: {
    link: 'Ver detalles',
  },
};

export default general;
