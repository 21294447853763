import { axios } from '@/plugins';

const fetchFlowFolders = async ({ commit, rootGetters }) => {
  const { flowFolders } = rootGetters['info/getRoutes'];

  try {
    const { data } = await axios.get(flowFolders);

    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const createFlowFolder = async ({ getters }, { name }) => {
  try {
    const url = getters.getLinks.createFolder;

    await axios.post(url, { name });
  } catch (err) {
    throw err;
  }
};

export default {
  fetchFlowFolders,
  createFlowFolder,
};
