<script setup>
import { onMounted, ref } from 'vue';
import { useModal, useToast } from '@base/hooks';
import { useI18n } from '@/locales';
import { FormFlowEmptyList, FormFlowCreateFolderModal } from '@flow/components/form-flow';
import { useStore } from '@/store';

const { isModalActive, openModal, closeModal } = useModal();
const toast = useToast();
const { t } = useI18n();
const store = useStore();

const isLoading = ref(false);

const fetchFlowFolders = async () => {
  isLoading.value = true;
  await store.dispatch('flowFolder/fetchFlowFolders');
  isLoading.value = false;
};

const handleErrorResponse = (err) => {
  const errors = err.response?.data?.flowFolder?.errors;

  if (errors) {
    Object.values(errors).forEach((value) => toast.error(value[0]));
  } else {
    toast.error(t('flowModule.folders.create.errorMessage'));
  }
};

const handleCreate = async ({ name }) => {
  try {
    await store.dispatch('flowFolder/createFlowFolder', { name });
    fetchFlowFolders();
    toast.success(t('flowModule.folders.create.successMessage'));
    closeModal();
  } catch (err) {
    handleErrorResponse(err);
  }
};

onMounted(async () => {
  await fetchFlowFolders();
});
</script>

<template>
  <div>
    <XCard>
      <FormFlowEmptyList
        icon="folder"
        :title="$t('flowModule.folders.emptyList.title')"
        :description="$t('flowModule.folders.emptyList.description')"
        :action-text="$t('flowModule.folders.emptyList.actionText')"
        action-design="outlined"
        @handle-new="openModal('FormFlowCreateFolderModal')"
      />
    </XCard>

    <FormFlowCreateFolderModal
      :show-modal="isModalActive('FormFlowCreateFolderModal')"
      @close="closeModal"
      @create="handleCreate"
    />
  </div>
</template>
