const signerArea = {
  tabs: {
    pending: {
      title: 'Pendentes',
    },
    signed: {
      title: 'Assinados',
    },
  },
  batchList: {
    sectionTitle: 'Minha área de assinatura',
    title: 'Assinaturas pendentes',
    subtitle: 'Confira e assine seus documentos aqui',
    documentsToSign: 'Documentos para assinar',
    signButton: 'Assinar',
    empty: {
      title: 'Sem assinaturas pendentes para você, por enquanto ',
      text: 'Agora você poderá ver e assinar seus documentos pendentes por aqui',
      help: 'Saiba mais sobre sua área',
    },
    headers: {
      sender: 'Remetente',
      documentation: 'Dados de cadastro',
      documentsCount: 'Documentos',
      deadline: 'Assinável até',
      selectAll: 'Selecionar todos',
      selectAllMobile: 'Selecionar todos da página',
    },
    documentationKind: {
      person: 'CPF: {0}',
      company: 'CNPJ: {0}',
    },
    noDocumentation: 'CPF/CNPJ não informado',
    documentsCount: '{0} documento | {0} documentos',
    deadline: 'Assinável até {0} | {0}',
    seeMore: 'Ver mais documentos',
  },
  receiptList: {
    title: 'Documentos assinados',
    deadlineAt: 'Em {date}',
    deadlineUntil: 'Até {date}',
    signedAt: 'Assinado em {date}',
    empty: {
      title: 'Sem documentos assinados, por enquanto ',
      text: 'Você poderá encontrar e baixar seus documentos assinados por aqui',
      help: 'Saiba mais sobre sua área',
    },
    documentStatuses: {
      canceled: 'Cancelado',
      closed: 'Finalizado',
      running: 'Em processo',
    },
    table: {
      header: {
        documents: 'Documentos',
        signers: 'Signatários',
        status: 'Status',
        download: 'Baixar',
      },
    },
    signers: {
      showLess: 'Ver menos signatários',
      showMore: 'Ver mais signatários',
    },
  },
  batchWarning: {
    waiting: {
      title: 'Aguardando outros signatários',
      text: 'Há outros signatários que precisam assinar antes de você. Tente assinar novamente em outro momento.',
    },
    allUnable: {
      title: 'Indisponível para assinar',
      text: 'Todos os documentos deste lote não podem ser assinados no  momento. Confira as possíveis razões:',
      status: {
        blocked: 'O processo de assinatura está temporariamente pausado',
        finished: 'O processo de assinatura foi finalizado ou cancelado',
        signed: 'Você já assinou este documento em conjunto com outros, anteriormente',
        refused: 'Você recusou a assinatura destes documentos anteriormente',
        unlinked:
          'O remetente do documento fez alterações no documento em processo e você não é mais um signatário deste lote',
      },
    },
    partialUnable: {
      title: 'Documento indisponível | Documentos indisponíveis',
      text: '{0} de {1} documentos deste lote não poderão ser assinados pelos seguintes motivos:',
      status: {
        blocked: '{0} - Documento pausado - aguardando ação do remetente',
        finished: '{0} - O processo de assinatura foi finalizado ou cancelado',
        signed: '{0} - já foi assinado por você em outro lote',
        refused: '{0} - já foi recusado por você em outro lote',
        unlinked:
          '{0} - O remetente do documento fez alterações neste documento em processo e você não é mais um signatário',
        notSignable: '{0} - Não é possível assinar o documento neste momento',
      },
    },
  },
  ctaCard: {
    title: 'Comece a enviar documentos para assinatura',
    imageAlt: 'Pessoa usando tablet',
    advantages: {
      advantage1: 'Validade jurídica',
      advantage2: 'Assinaturas digital e eletrônica',
      advantage3: 'Envios via WhatsApp, SMS ou e-mail',
      advantage4: 'Diversos tipos de Autenticação ',
    },
    action: 'Testar grátis',
  },
  helpCard: {
    title: 'Precisa de ajuda?',
    helpCentral: 'Perguntas frequentes',
    support: 'Contato com o suporte',
    sales: 'Contato com vendas',
  },
  topbar: {
    dropdown: {
      myArea: 'Minha área de assinatura ',
      signerDashboard: 'Documentos para assinar',
      accounts: 'Contas',
      config: 'Configurações do perfil',
      logout: 'Sair',
    },
    action: {
      title: 'Enviar documento para assinar',
      subtitle: 'Acessar conta para fazer o envio',
    },
  },
  cpfConfirmation: {
    title: 'Confirmação de CPF',
    subtitle: 'Insira o CPF abaixo e verifique se existem documentos pendentes',
    cpfLabel: 'CPF',
    confirmSuccess: 'CPF confirmado com sucesso',
    confirmError: 'Erro ao cadastrar. Tente novamente.',
    noCpf: 'Não possuo CPF',
  },
  dataConfirmation: {
    title: {
      phoneValidation: 'Confirme seu celular e descubra se há outros documentos pendentes',
      cpfBirthdayValidation: 'Insira seu CPF e descubra se há documentos pendentes',
    },
    actions: {
      confirm: 'Confirmar',
      complete: 'Completar',
    },
    placeholder: {
      cpf: 'CPF',
      birthday: 'Data de nascimento',
    },
    completeSignupSuccess: 'Cadastro feito com sucesso!',
    completeSignupError: 'Erro ao completar cadastro!',
  },
  didntFindYourDocuments: 'Não encontrou seu documento?',
  fakeDoorActivator: 'Procurando documentos já assinados?',
  fakeDoorModal: {
    titleSmall: 'Saiba onde encontrar os documentos já assinados',
    title: 'Documentos já assinados',
    paragraph1:
      'No momento, só é possível visualizar um documento que você já assinou acessando a cópia enviada por e-mail ou WhatsApp.',
    paragraph2: 'Estamos trabalhando para melhorar sua experiência e em breve traremos novidades!',
    action: 'Entendi',
  },
  clearPhoneNumberModal: {
    title: 'Tem certeza disso?',
    text: 'Ao não salvar seu número de celular na plataforma, futuramente podem surgir documentos atrelados a ele e esses documentos não serão apresentados aqui.',
  },
  phoneNumberValidation: {
    title: {
      pending: 'Confirme seu celular e receba documentos atrelados a esse número',
      empty: 'Adicione seu celular e receba documentos atrelados a esse número',
      expiring: 'Esse número de celular ainda é seu?',
    },
  },
  cellPhoneConfirmation: {
    title: 'Confirme seu celular e descubra se há outros documentos pendentes',
    button: 'Confirmar',
    phoneNumberValidation: {
      success: 'Alteração salva com sucesso',
      failed: 'Número de celular inválido',
      confirm: 'Confirmação realizada com sucesso',
    },
    loadingSave: 'Buscando documentos enviados ao celular cadastrado...',
    modal: {
      header: 'Validação do celular',
      title: 'Token de validação',
      info: 'Verifique o token enviado via SMS para o celular {0}{1} e insira-o abaixo.',
      changeNumberLink: 'Alterar número de celular',
      insertToken: 'Insira o Token para finalizar',
      resendSMS: 'Reenviar Token via SMS',
      timeToResend: 'Aguarde {timer} para reenviar',
      noDocuments: 'Não encontramos documentos enviados ao celular cadastrado',
      success: 'Encontramos documentos enviados ao celular cadastrado',
      actions: {
        close: 'Fechar',
        confirm: 'Confirmar',
      },
      tokenValidation: {
        failed: 'Token inválido',
        success: 'Token reenviado com sucesso!',
      },
    },
  },
  notificationConfigs: {
    dismissEmailOptions: 'Desmarque o(s) e-mail(s) que não quer receber',
    signatureReceipt: 'Lembretes sobre data limite de assinatura',
    signatureDeadlineReminder: 'Comprovante de assinatura',
    signedDocumentFinished: 'Documento assinado e finalizado',
    updatedWithSuccess: 'Alterações salvas com sucesso',
    goToSignatureArea: 'Ir para área de assinaturas',
  },
};

export default signerArea;
